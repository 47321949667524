export const APP_TITLE = process.env.REACT_APP_TITLE;
export const REACT_APP_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const REACT_APP_FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const REACT_APP_APPLE_APP_ID = process.env.REACT_APP_APPLE_APP_ID;
export const CRYPTO_SECRET_KEY = "ztohxhkzvgxzjxgyzzmg";
export const API_URL = process.env.REACT_APP_BACKEND_API_URL;
// AWS
export const AWS_TEMP_URL_VALIDITY_HOUR = 24;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;
// firebase
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
// login app details
export const APP_CODE = "1.0.0";
export const DEVICE_TYPE = "W";
export const APP_SETTINGS_REFRESH_EVERY_MINUTES = 20;
// default aws image
export const DEFAULT_AWS_PROFILE_IMAGE = "public/upload/profile/user.jpg";
export const DEFAULT_AWS_IMAGE = {
  profile: "public/upload/placeholder/profile.png",
  profileMale: "public/upload/placeholder/profile_male.png",
  profileFemale: "public/upload/placeholder/profile_female.png",
  companyLogo: "public/upload/placeholder/company.png",
};
// AZURE
export const AZURE_STORAGE_ACCOUNT_NAME =
  process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
export const AZURE_CONTAINER_NAME = process.env.REACT_APP_AZURE_CONTAINER_NAME;
export const AZURE_SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN;
export const AZURE_PROFILE_FOLDER = "profile";
export const AZURE_COMPANY_FOLDER = "company";
export const AZURE_RECIPE_FOLDER = "recipe";
// stripe
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// export const SHOW_LAUNCH_PAGE = true;
// export const LAUNCH_DATE = "2024-11-11T00:00:00.000Z";
// export const SHOW_LAUNCH_TIME = true;
export const SHOW_LAUNCH_PAGE =
  process.env.REACT_APP_SHOW_LAUNCH_PAGE || "false";
export const LAUNCH_DATE = "2025-02-01T00:00:00.000Z";
export const SHOW_LAUNCH_TIME =
  process.env.REACT_APP_SHOW_LAUNCH_TIME || "true";

// currency
export const DEFAULT_CURRENCY = "$";
